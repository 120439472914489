import jsonCategories from './categories.json'
import jsonPlants from './plants.json'
import {useState} from "react";
import SelectionPDF from "./SelectionPDF";
import {PDFViewer} from "@react-pdf/renderer";
function Catalog() {
    const [plants, setPlants] = usePlantsList()
    return (
        <div className={"catalog"}>
            <ListPlants plants={plants}/>
        </div>
    )
}

function ListPlants(props) {
        return (
            <div className={"catalog-list-categorized"}>
                    {jsonCategories.map(category =>
                        <ListPlantCategory key={category.code} category={category} plants={props.plants}/>
                    )}
            </div>
        )
}

function ListPlantCategory(props) {
    const [active,setActive] = useState(false)
    return (
        <div key={props.category.code} className={active?"catalog-list-category":"catalog-list-category collpased"}>
            <div className={"catalog-list-category-header"}>
                <div className={`pastille category-${props.category.code}`}>

                </div>
                <div className="catalog-list-category-title" onClick={()=>{
                    setActive(!active)
                }}>

                    <span className="bold">{props.category.nom}</span>
                    {props.category.variante?<span className="category-variant">{props.category.variante}</span>:""}
                </div>
            </div>
                <div className={active?"catalog-list":"catalog-list hide"}>
                    {props.plants.filter(p=> p.plant.categories[0]== props.category.code).map( plantItem =>
                        <PlantItemCard key={plantItem.plant.nom} category={props.category.nom} plantItem={plantItem}/>
                    )}
                </div>

        </div>
    )
}

function PlantItemCard(props) {
    const plantItem = props.plantItem;
    const [key,setKey] = useState(false);
    const [plants, setPlants] = usePlantsList()

    const select = () => {
        plantItem.selected = true
        setPlants(plants)
    }

    return (
        <div key={plantItem.plant.nom} className={plantItem.selected && plantItem.selected===true?"catalog-list__item-plan selected":"catalog-list__item-plan"}>
            <div className={`catalog-list__item-plan_title`} onClick={select}>
                <span className={"card-title"}>{plantItem.plant.nom}</span>
                <div className={`pastille category-${plantItem.plant.categories[0]}`}>

                </div>
            </div>
            <div className="catalog-list__item-plan_body">
                {plantItem.plant.images?
                    <div className="photo">
                        <img alt={`${props.category} - ${plantItem.plant.nom} - ${plantItem.plant.couleur}`} src={plantItem.plant.images[0]}/>
                    </div>
                    :''}
                <div className="description">
                    <div className="labeled-info fruit">
                        <div className="title">
                            <label>Fruit</label>
                        </div>
                        <div className="value">
                            <span>{plantItem.plant.fruit}</span>
                        </div>
                    </div>
                    <div className="labeled-info saveur">
                        <div className="title">
                            <label>Saveur</label>
                        </div>
                        <div className="value">
                            <span>{plantItem.plant.saveur}</span>
                        </div>
                    </div>
                    {plantItem.plant.origine ?
                        <div className="labeled-info origine detailed">
                            <div className="title">
                                <label>Origine</label>
                            </div>
                            <div className="value">
                                <span>{plantItem.plant.origine}</span>
                            </div>
                        </div>
                        : ""}

                    {plantItem.plant.chair ?
                        <div className="labeled-info chair detailed">
                            <div className="title">
                                <label>Chair</label>
                            </div>
                            <div className="value">
                            <span>{plantItem.plant.chair}</span>
                            </div>
                        </div>
                        : ""}

                    {plantItem.plant.plante ?
                        <div className="labeled-info plante detailed">
                            <div className="title">
                                <label>Plante</label>
                            </div>
                            <div className="value">
                            <span>{plantItem.plant.plante}</span>
                            </div>
                        </div>
                        : ""}

                    {plantItem.plant.utilisation ?
                        <div className="labeled-info plante detailed">
                            <div className="title">
                                <label>Utilisation</label>
                            </div>
                            <div className="value">
                            <span>{plantItem.plant.utilisation}</span>
                            </div>
                        </div>
                        :""
                    }
                </div>

            </div>
            <div className="catalog-list__item-plan_footer">
                <div className="tarif">
                    <label>Tarif:</label>
                    <span>{plantItem.plant.tarif} €</span>
                </div>
                <div>
                    <label></label>

                </div>
            </div>
        </div>
    )
}

function CategoriesNavigation() {
    return (
        <div className={"categories-navigation"}>
            {jsonCategories.map(category =>
                <div className="category-nav-item" key={category.nom}>
                    <span className="bold">{category.nom}</span>
                    {category.variante?<span>{category.variante}</span>:""}
                </div>
            )}
        </div>
    )
}



export const usePlantsList = () => {
    const selectablePlants = jsonPlants.map(element => {
        const item = {
            plant:element,
            selected:false,
            qte:0
        }
        return item
    })
    const [plants, setPlants] = useState(selectablePlants);
    return [plants, setPlants]
}

export const useCategoriesList = () => {
    const [categories, setCategories] = useState(jsonCategories);
    return categories
}


export default Catalog
