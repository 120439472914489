import logo from './logo.svg';
import './App.css';
import Catalog, {usePlantsList} from "./Catalog";
import {JsonEditor} from "./JsonEditor";
import {PlantForm} from "./forms";
import Infos from "./Infos";
import Contact from "./Contact";

function App() {
    const [plants, setPlants] = usePlantsList()
  return (
    <div className="App">
      <header className="App-header">
          <div className={"logo-title-group"}>
              <img src={logo} className="App-logo" alt="logo" />
              <div className="titles">
                  <span className="site-title">Plant' Eure</span>
                  <span className="title">Catalogue 2024</span>
              </div>
          </div>
          <Contact/>

      </header>
        <div className="header-2">
            <Infos/>
        </div>
        <main>
            <Message/>
            <Catalog/>
        </main>
    </div>
  );
}

function MessageHorsSaison(){
    return (
        <div className={"message"}>
            <p>
                La saison des plants de 2023 est terminée.
                <br/>Nous vous remercions pour votre confiance et les discussions partagées avec les jardiniers et jardinières rencontrées.
                <br/>Nous espérons que vous serez pleinement satisfaits et vous revoir nombreux l'an prochain avec ces sourires qui nous encourage.
                <br/>Bonnes dégustations à toutes et tous
            </p>
        </div>
    )
}
function Message() {
    return (
        <div className={"message"}>
            <div className={'message-left'} style={{ backgroundImage:`url(assets/images/illustration-serre-2.png)` }}>
                <h2>Rendez-vous</h2>
                <ul className={'agenda'}>
                    <li><span className={'agenda-jour'}>Hiver 2025 </span><span>Pour le nouveau catalogue</span> </li>

                </ul>
            </div>

            <p>
                Chers jardiniers et jardinières,
                <br/>la saison se termine pour nous et nous remercions pour la confiance accordée une nouvelle fois.
                <br/>Nous vous donnons rendez vous à l'hiver 2025 pour découvrir le catalogue des productions de l'an prochain.
                <br/>Au plaisir de vous retrouver.
                <br/>Carine et Nicolas
            </p>
        </div>
    )
}
export default App;
