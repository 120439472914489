import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});
const SelectionPDF = (props) => {
    const plantItems = props.plantItems
    return (
    <Document>
        <Page size="A4" style={styles.page}>
            {plantItems.filter(item => item.selected).map(
                plantItem =>
                    <View style={styles.section}>
                        <Text>{plantItem.plant.nom}</Text>
                    </View>
            )}
        </Page>
    </Document>
)};

export default SelectionPDF