import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";

const Infos = () => {

    return(
        <>
            <Carousel swipeable={true} showArrows={false} showIndicators={false} showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} interval={12000}>
                <div key="slide1" >
                <span className="slogan">
                    Nous sommes installés à Bourg-Achard dans l’Eure, et nous pratiquons le jardin avec des convictions agroécologiques et citoyennes
                </span>
                </div>
                <div key="slide2" >
                <span className="slogan">
                    L'ensemble des plants que nous proposons sont issus de semences reproductibles que je m'efforce de sélectionner chaque année pour leurs qualités gustatives
                </span>
                </div>
                <div  key="slide3" >
                <span className="slogan">
                    Nous ne sommes pas certifiés, cependant, nous utilisons un terreau bio et nous excluons l'emploi de phytosanitaires.
                </span>
                </div>
                <div  key="slide4" >
                <span className="slogan">
                Le produit star est la tomate, ancienne ou de collection issues de croisement, retrouvé, préservé, effectué et stabilisé par des passionnés du monde entier
                </span>
                </div>
                <div  key="slide5" >
                <span className="slogan">
                    Les photographies et les descriptifs des variétés sont empruntés à l ‘association Tomatofifou avec leur accord. Retrouver les sur leur site : <a target={'_blank'} href={'https://www.tomatofifou.com/'}>https://www.tomatofifou.com/</a> - Merci de les soutenir
                </span>
                </div>
                <div  key="slide6" >
                <span className="slogan">
                Les plants devraient être disponibles à partir de la mi-avril environ soit au jardin sur rendez-vous, soit sur des foires et des marchés de la région.
                </span>
                </div>

                <div  key="slide7" >
                <span className="slogan">
                    En fonction des quantités disponibles, il y aura peut-être lieu de faire des ajustements à vos commandes, selon vos recommandations.
                </span>
                </div>
            </Carousel>
        </>
    )
}

export default Infos
